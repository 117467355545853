import { Component, OnInit } from '@angular/core';
import {Title} from '@angular/platform-browser';
import { Router, NavigationEnd } from '@angular/router';
import { filter, map, delay } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { LoadingSpinnerService } from 'src/app/_services/loading-spinner/loading-spinner.service';
import Intercom from '@intercom/messenger-js-sdk';
import {AuthService} from '../../_services/auth/auth.service';
import {environment} from '../../../environments/environment';
import {UsersService} from "../../_services/users/users.service";
import {Regions} from "@intercom/messenger-js-sdk/dist/types";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit {
  isLoading = false;
  loader = '../../../assets/images/loading.svg';
  constructor(
    private titleService: Title,
    private router: Router,
    private translateService: TranslateService,
    private loading: LoadingSpinnerService,
    private userService: UsersService,
  ) {}

  ngOnInit() {
    Intercom({
      region: Regions.EU,
      app_id: environment.intercom_app_id,
    });
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => this.router)
      )
      .subscribe((event) => {
        const title =
          this.getTitle(
            this.router.routerState,
            this.router.routerState.root
          ).join(' | ');
        this.titleService.setTitle(title);
      });

    this.listenToLoading();
    this.userService.userUpdated.subscribe((user) => {
      Intercom({
        region: Regions.EU,
        app_id: environment.intercom_app_id,
        user_id: user.id, // IMPORTANT: Replace "user.id" with the variable you use to capture the user's ID
        name: user.name, // IMPORTANT: Replace "user.name" with the variable you use to capture the user's name
        email: user.email, // IMPORTANT: Replace "user.email" with the variable you use to capture the user's email
        created_at: user.created_at, // IMPORTANT: Replace "user.createdAt" with the variable you use to capture the user's sign-up date in a Unix timestamp (in seconds) e.g. 1704067200
        user_hash: user.user_hash,
        company: {
          id: user.organizations[0]?.id,
          name: user.organizations[0]?.name,
          role: user.roles[0] ?? null
        },
      });
    });
  }

  getTitle(state, parent) {
    const data = [];
    if (parent && parent.snapshot.data && parent.snapshot.data.title) {
      data.push(this.translateService.instant(parent.snapshot.data.title));
    }

    if (state && parent) {
      data.push(...this.getTitle(state, state.firstChild(parent)));
    }
    return data;
  }

  listenToLoading(): void {
    this.loading.loadingSub
      .pipe(delay(0))
      .subscribe((loading) => {
        this.isLoading = loading;
      });
  }
}
